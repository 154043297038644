import React from 'react';
import useFetchReviews from '../../utils/hooks/useFetchReviews';
import { StarIcon } from '@heroicons/react/24/outline';
import Rating from './Rating';
import { json } from 'stream/consumers';

type Props = {
    sku: string;
};

const ProductRating = ({ sku }: Props) => {
    const { data, loading, error } = useFetchReviews(sku);

    if (loading || data === null) {
        return (
            <div className="flex items-center">
                <StarIcon className="text-gray-300 w-5 h-5" />
                <StarIcon className="text-gray-300 w-5 h-5" />
                <StarIcon className="text-gray-300 w-5 h-5" />
                <StarIcon className="text-gray-300 w-5 h-5" />
                <StarIcon className="text-gray-300 w-5 h-5" />
            </div>
        );
    }

    const { stats } = data;
    return (
        <div className="mt-3 flex justify-center lg:justify-start">
            <Rating rating={stats.average} />
        </div>
    );
};

export default ProductRating;
