import { useEffect, useState } from 'react';
import { PRODUCT_REVIEWS_ENDPOINT } from '../constants/endpoints';
import axios from 'axios';

const useFetchReviews = (sku: string, page = 1) => {
    const [data, setData] = useState<any>(null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const controller = new AbortController();
        axios
            .get(PRODUCT_REVIEWS_ENDPOINT + sku + '&page=' + page, {
                signal: controller.signal,
            })
            .then((res) => {
                setData(res.data);
            })
            .catch(setError)
            .finally(() => setLoading(false));

        return () => {
            controller.abort();
        };
    }, [sku, page]);

    return { data, error, loading };
};

export default useFetchReviews;
